<template>
  <v-app>
    <v-navigation-drawer v-if="loggedIn" :disable-resize-watcher="true" :disable-route-watcher="true" mobile-display="500" v-model="drawer" width="280">

      <template v-if="$global.checkRoles(this.user, ['ALL'])"><router-link to="/"><div>Главная</div></router-link></template>
      <template v-if="$global.checkRoles(this.user, ['SUDO'])"><router-link to="/settings"><div>Настройки</div></router-link></template>

    </v-navigation-drawer>

    <v-app-bar v-if="loggedIn" app :clipped-left="$vuetify.display.mdAndUp" :absolute="true">

      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }">
            <v-app-bar-nav-icon v-bind="tooltip" :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="drawer = !drawer"></v-app-bar-nav-icon>
          </template>
          <span>МЕНЮ</span>
        </v-tooltip>

        <router-link to="/" v-if="$vuetify.display.mdAndUp"><span>OPENAI</span></router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip location="bottom" v-if="false">
        <template v-slot:activator="{ props: tooltip }">
          <v-btn v-bind="tooltip" icon v-if="$global.checkRoles(this.user, ['SUDO'])" :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp">{{ Math.ceil(idleCountdown) }}</v-btn>
        </template>
        <span>ОТСЧЕТ ДО ВЫХОДА</span>
      </v-tooltip>

      <v-tooltip location="bottom">
        <template v-slot:activator="{ props: tooltip }">
          <router-link v-bind="tooltip" v-if="$global.checkRoles(this.user, ['ALL']) && !$vuetify.display.mdAndUp" to="/"><v-btn icon small><v-icon>mdi-home</v-icon></v-btn></router-link>
        </template>
        <span>НА ГЛАВНУЮ</span>
      </v-tooltip>

      <language-selector v-if="$global.checkRoles(this.user, ['SUDO']) && false" :languages="$i18n.availableLocales" :current-language="$i18n.locale"></language-selector>


      <v-tooltip location="bottom" v-if="$global.checkRoles(this.user, ['SUDO'])">
        <template v-slot:activator="{ props: tooltip }">
          <v-btn v-bind="tooltip" icon="mdi-account-key" :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="router().push('/settings')"></v-btn>
        </template>
        <span>НАСТРОЙКИ</span>
      </v-tooltip>

      <v-tooltip location="bottom">
        <template v-slot:activator="{ props: tooltip }">
          <v-btn v-bind="tooltip" icon="mdi-theme-light-dark" :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="toggleTheme()"></v-btn>
        </template>
        <span>ДЕНЬ/НОЧЬ</span>
      </v-tooltip>

      <v-tooltip location="bottom">
        <template v-slot:activator="{ props: tooltip }">
          <v-btn v-bind="tooltip" icon="mdi-run" :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="handleLogout(true)"></v-btn>
        </template>
        <span>ВЫХОД</span>
      </v-tooltip>

    </v-app-bar>

    <v-main>
      <slot/>
    </v-main>

    <!--  SHUTDOWN TIMER  -->
    <v-snackbar timeout="-1" location="top" v-model="$store.state.shutdownObj.run" vertical>
      <div class="text-subtitle-1 pb-2">Система будет отключена для проведения технических работ через {{ $store.state.shutdownObj.min }} минут.</div>
      <p v-if="$store.state.shutdownObj.len > 0">Время проведения технических работ: {{ $store.state.shutdownObj.len }} минут.</p>
    </v-snackbar>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-app>
</template>
<script>

// import axios from 'axios';
import ConfirmDialog from "../ConfirmDialog";
import LanguageSelector from "../LanguageSelector";
import { useTheme } from 'vuetify'
import axios from "axios";
import router from "@/router/router";
// import Vue from 'vue';
// import DatetimePicker from 'vuetify-datetime-picker'
// Vue.use(Clipboard);
// Vue.use(DatetimePicker);

export default {

  name: 'DefaultLayout',
  setup () {
    const theme = useTheme();
    return { theme, toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'};
  },
  components: {
    ConfirmDialog, LanguageSelector
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,

      drawer: false,
      dialog: false,
      items: [],
      breadcrumbs: [],
      serverAvailable: false,

      idleTime: 30000, // The internal timer that tracks idle time, is reset on event. Set this to your timeout value in seconds
      idleCountdown: null, // Populated at runtime by idleTime value. This value decrease every second until timeout is reached
      idleTimeout: false, // Timeout status toggle

    }
  },
  watch: {
    // '$vuetify.display.mdAndUp'(val) {
    //   this.drawer = val;
    // },
    drawer() {
      this.$store.dispatch('handleToggleSideBar');
    }
  },
  mounted() {
    this.$store.dispatch("websocket/connect");
    let self = this;
    document.addEventListener.call(window, "mousemove", () => {
      self.idleReset();
    });
    // this.drawer = this.$vuetify.display.mdAndUp;
  },
  created() {
    console.log("DEFAULT LAYOUT");
    this.checkIsServerAvailable()
    this.startIdleCountdown();
  },
  methods: {
    router() {
      return router
    },
    startIdleCountdown() {
      this.idleCountdown = this.idleTime;                     // initialize the timer with idle timeout config value
      this.setIdleTimer = setInterval(this.idleTimer, 1000);  // starts the timer. setTimer is used to clear/reset idleTimer
    },
    idleTimer() {
      this.idleCountdown = this.idleCountdown - 1;        // decrement idleCountdown every second
      if (!this.idleCountdown) {                            // if countdown is zero timeout is achieved
        clearInterval(this.setIdleTimer);               // clear timer
        this.idleTimeout = !this.idleTimeout;           // toggle the idle timeout status
        this.countdownExpired();                        // post UI countdown expiry events
      }
    },
    idleReset() {
      if (!this.idleTimeout) {                                 // reset idle countdown only if idle timeout has not expired
        clearInterval(this.setIdleTimer);                   // reset the UI timer
        this.startIdleCountdown();                          // start the idle countdown
      }
    },
    countdownExpired() {
      this.handleLogout(false);
    },

    handleLogout(quit) {
      let self=this;
      if (quit) this.$refs.confirm.open('Внимание!', 'Завершить работу с приложением?', {color: '#9C0A20'}).then((result) => {
        if (result) {
          self.$store.dispatch('auth/logout');
        }
      });
      else {
        this.$store.dispatch('auth/logout');
      }
    },

    checkIsServerAvailable() {
      let self = this;
      if(this.$store.state.auth.status.loggedIn ) {
        axios({method: 'post', url: this.restUrl + '/rest/authenticated/ping', headers: this.$global.headers(this.$store.state.auth.user),}).then(function (response) {
          self.serverAvailable = response.data;
          clearTimeout(self.timeout);
          if (!self.serverAvailable) {
            self.$store.dispatch('auth/logout');
          } else {
            self.timeout = setTimeout(function () {
              self.checkIsServerAvailable()
            }, 6000);
          }
        }).catch(function () {
          self.serverAvailable = false;
          clearTimeout(self.timeout);
          if (!self.serverAvailable) {
            self.$store.dispatch('auth/logout');
          }
        })
      } else {
        router.push('/start')
      }
    },

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
}


</script>
