// import Vue from 'vue';
import moment from "moment";
const global = {
    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    headers(user) {
        return user && user.token?
            {
                'Authorization': 'Bearer ' + user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            } :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
    },
    headersMf(user) {
        return user && user.token?
            {
                'Authorization': 'Bearer ' + user.token,
                'Content-Type': 'multipart/form-data'
            } :
            {
                'Content-Type': 'multipart/form-data'
            };
    },
    displayDate(date, format) {
        let result = '';
        if (date != null && date !== '') result = moment(date).format(format);
        return result;
    },
    doNothing() {
    },
    forceFileDownload(data, filename) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click()
    },
    getImageUrl(image, large, folder, restUrl) {
        return restUrl + '/media/' + folder + '/' + (large ? image.fsName : image.fsNameSm);
    },
    checkRoles(user, roles) {
      if(user) {
          let role = user.roles;
          if (roles.includes('ALL')) return true;
          return roles.includes(role);
      } else return false;
    },
    isNumeric(str) {
        return /^-?\d+$/.test(str);
    },
    inputNumberGE(v, X) {
        try {
            v = parseFloat(v);
            if(v < X) {
                return X;
            }
            return v;
        } catch (e) {
            console.log(e);
            return X;
        }
    },
}
export default {
    global
}

