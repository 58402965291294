<style>
.key {
  color: green;
}
.number {
  color: blue;
}
.string {
  color: yellow;
}
</style>
<template>

  <v-dialog :model-value="show" persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="10">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>DATA</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-card-text>
        <v-table :density="'compact'" height="350">
          <thead>
          <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Section start</td>
            <td>Section end</td>
            <td>Start coordinate</td>
            <td>End coordinate</td>
          </tr>
          </thead>
          <tbody>
          <lazy-component wrapper-tag="tr" v-for="i in item.sections" :key="i.id" >
            <td>{{ i.id }}</td>
            <td>{{ i.name }}</td>
            <td>{{ i.section_start }}</td>
            <td>{{ i.section_end }}</td>
            <td>{{ i.start_coordinate }}</td>
            <td>{{ i.end_coordinate }}</td>
            <template #placeholder>
              <tr>
                <td colspan="15">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>
        </v-table>


      </v-card-text>
    </v-card>
  </v-dialog>


</template>

<script>


export default {
  components: {
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    item: {type: Object, required: true, default() {return {}}},
  },
  data() {
    return {
      windowWidth: 0,
    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});

  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
  },

  methods: {
    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
