import {Client} from '@stomp/stompjs';

export const websocket = {
    namespaced: true,

    state: {
        websocketUrl: process.env.VUE_APP_BACKEND_WS_URL + '/ws',
        stompClient: null,
        subscriptionUpdateMessages: null,
    },

    getters: {
        getStompClient: (state) => state.stompClient,
        getSubscriptionUpdateMessages: (state) => state.subscriptionUpdateMessages,
    },

    mutations: {
        setStompClient(state, value) {
            state.stompClient = value;
        },
        deactivateClient(state, context) {
            if (context.state.stompClient) {
                if (context.state.subscriptionUpdateMessages) {
                    context.getters.getSubscriptionUpdateMessages.unsubscribe();
                    context.commit('setSubscriptionUpdateMessages', null);
                }
                context.getters.getStompClient.deactivate();
                context.commit('setStompClient', null);
            }
        },
        activateClient(state, context) {
            context.getters.getStompClient.onConnect = function () {
                context.commit('setSubscriptionUpdateMessages', context.getters.getStompClient.subscribe('update_messages', function (message) {
                    console.log("message: update messages");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateMessages", val, {root: true});
                }));
            };
            context.getters.getStompClient.onStompError = function (frame) {
                console.log('Broker reported error: ' + frame.headers['message']);
                console.log('Additional details: ' + frame.body);
            };

            context.getters.getStompClient.onDisconnect = function () {
                console.log('deactivation');
            };
            context.getters.getStompClient.activate();

        },
        setSubscriptionUpdateMessages(state, value) {
            state.subscriptionUpdateMessages = value;
        },
    },

    actions: {
        disconnect(context) {
            context.commit('deactivateClient', context)
        },
        connect(context) {
            const settings = {
                brokerURL: context.state.websocketUrl,
                debug: function (str) {
                    console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,

            };
            if (!context.getters.getStompClient) {
                context.commit('setStompClient', new Client(settings));
            }
            context.commit('activateClient', context)
        },
    },
}
