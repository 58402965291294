<template>
  <default-layout-dynamic>

    <div v-if="$global.checkRoles(this.user, ['SUDO'])" @click="openDialog(users)"><a href="#">Пользователи</a></div>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <users v-model="pagination" :items='items' :template="template" :roles="roles" :show="usersDialog" @close="closeDialog(id)" @save-item="saveItem" :mobile="m" @refresh="getItems(id)"></users>

  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import Ahtung from "../components/Ahtung";
import Users from "../components/settings/Users";
import router from "@/router/router";

export default {
  components: {
    Users,
    DefaultLayoutDynamic,
    Loader,
    Ahtung
  },
  data() {
    return {
      users: 'users',
      id: '',

      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      usersDialog: false,

      pagination: {pp: 20, page: 1, pages: 0, total: 0, search: '', stores: [], users: []},

      items: [],
      total: 0,
      template: {},
      roles: [],
      profiles: [],
      currentProfile: [],

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      m: true,
    }
  },
  name: "SettingsPage",
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    console.log("SETTINGS");
    if (!this.loggedIn) router.push('/start')
  },
  methods: {

    openDialog(id, skip_fetching) {
      this.id = id;
      if(!skip_fetching) {
        this.getItems(this.id);
      }
      this[this.id + 'Dialog'] = true;
    },

    getItems(id) {
      this.displayLoader = true;
      let self = this;
      self.items = [];
      let json = {pagination: this.pagination};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/" + id + "/items",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.roles = response.data.roles;
        self.items = response.data.items;
        self.total = response.data.total;
        self.pagination.pages = response.data.pages;
        self.pagination.total = response.data.total;
        if (self.pagination.page > self.pagination.pages && self.pagination.pages !== 0) self.pagination.page = self.pagination.pages;
        self.template = response.data.template;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки элементов. " + id;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
        console.log(e);
      });
    },
    saveItem(item, id) {
      this.displayLoader = true;
      let self = this;
      let json = {item: item};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/" + id + "/save",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        console.log(response.data)
        if (response.data.status) {
          self.getItems(id);
        } else {
          if(response.data.error) {
            self.alert_message = "Ошибка сохранения элемента. " + response.data.error;
          } else {
            self.alert_message = "Ошибка сохранения элемента. " + id;
          }
          self.alert_header = "Внимание!";
          self.alert_switch = true;
        }
        self.displayLoader = false;
      }).catch(function (e) {
        console.log(e);
        self.alert_message = "Ошибка сохранения элемента. " + id;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    closeDialog(id) {
      this[id + 'Dialog'] = false;
      this.id = '';
      this.pagination = {pp: 20, page: 1, pages: 0, total: 0, search: '', stores: [], users: []};
    },
  },
  watch: {
    'pagination.page'() {
      if(this.id.length > 0) this.getItems(this.id);
    },
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
