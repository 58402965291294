<style>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" persistent :scrollable="true" >
    <v-card style="overflow-x: hidden">

      <v-card-title class="headline">
      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>Настройки пользователей</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-btn :block="true" @click="editItem(template)"><v-icon>mdi-account-plus</v-icon></v-btn>

        <v-col cols="12" v-if="items.length > 0 && val.pages > 1">
          <v-pagination v-model="val.page" :length="val.pages" :rounded="true" size="x-small"></v-pagination>
        </v-col>

        <v-table  v-if="!mobile"  :fixed-header="true" height="600" :density="'compact'">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{h.text}}
            </th>
          </tr></thead>
          <tbody>
            <tr v-for="item in items" :key="item.id"  @dblclick="editItem(item)">
             <td>{{ item.id }}</td>
             <td>{{ item.role }}</td>
             <td>{{ item.name }}</td>
             <td>{{ item.login }}</td>
            </tr>
          </tbody>
        </v-table>
        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in items" :key="item.id">
          <tbody @click="editItem(item)">
          <tr>
            <td class="cell-mobile" style="width: 10%; text-align: left;">
              <b>{{ item.id }}</b>
            </td>
            <td class="cell-mobile" style="width: 60%; text-align: left;">
              <b><i style="color: #109f08;">{{ item.name }}</i></b>
            </td>
            <td class="cell-mobile" style="width: 30%; text-align: left;" colspan="2">
              {{ item.role }}
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 30%" colspan="4">
              {{ item.login }}
            </td>
          </tr>
          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>
      </v-card-text>

    </v-card>

    <v-dialog :fullscreen="mobile" v-model="editDialog" :width="getModalWidth" :scrollable="true" persistent>
      <v-card v-if="item && editDialog">
        <v-card-title class="headline">
            <v-row :no-gutters="true">
              <v-col cols="7">
                <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ (item.id === 0 ? 'Создание нового элемента' : 'Редактирование элемента #' + item.id) }}</b></span>
              </v-col>
              <v-col style="text-align: right">
                <v-tooltip style="display: inline-block" top>
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="cancelEditItem()"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Закрыть</span>
                </v-tooltip>
              </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>


        <v-card-text v-if="item">

            <v-text-field variant="solo" :hide-details="true" density="compact" label="Имя" v-model="item.name"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" density="compact" label="Логин*" v-model="item.login"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" density="compact" label="Пароль*" v-model="item.password"></v-text-field>
          <v-select variant="solo" :disabled="item.role === 'SUDO' || roles.length <= 1" :hide-details="true" v-if="!item.current" :items="roles" v-model="item.role" density="compact" label="Роль*"></v-select>


          <v-row :dense="true">
            <v-col cols="12" md="4"><v-checkbox v-model="item.use2FA" density="compact" :hide-details="true" label="2-х фактор."></v-checkbox></v-col>
            <v-col v-if="item.use2FA" cols="12" md="4"><v-text-field v-model="item.secret" density="compact" :hide-details="true" label="Google 2 FA Secret Key"></v-text-field></v-col>
            <v-col v-if="item.use2FA" cols="12" md="4"><v-btn :block="true" size="x-small" @click="generateSecret()">Сгенерировать</v-btn></v-col>
          </v-row>

          <v-row v-if="item.use2FA" :dense="true">
            <v-col cols="3"><v-img v-if="item.secret && item.secret.length > 5" :src="googleAuthImgUrl"></v-img></v-col>
          </v-row>

        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row :dense="true">
            <v-col cols="6" style="text-align: left">
              <v-btn x-small @click="saveItem()">
                <span>Сохранить</span></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>

  </v-dialog>


</template>

<script>

import Loader from "../Loader";
import Ahtung from "../Ahtung";
import axios from "axios";
import {computed} from "vue";

export default {
  name: "UsersSettings",
  components: {
    Loader,
    Ahtung
  },
  props: {
    modelValue: {type: Object, required: true, default() {return {}}}, //pagination
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},
    roles: {type: Array, required: true, default() {return []}},
    template: {type: Object, required: true, default() {return {}}},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Роль', value: 'role', field: 'role', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Имя', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Логин', value: 'login', field: 'login', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      item: {},
      editDialog: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

        showLogs: false,
        logs: [],
    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
    googleAuthImgUrl () {
      let result="#";
      if(this.item.login && this.item.use2FA && this.item.secret && this.item.secret.length > 5 ) {
        result = 'https://chart.googleapis.com/chart?chs=200x200&chld=M%%7C0&cht=qr&chl=otpauth://totp/zebra-shop:' + this.item.login + '?secret=' + this.item.secret + '&issuer=UTF-8';
      }
      return result;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    filteredForOwnerChangeItems(item) {
      let result = [];
      for (let newOwner of this.items) {
        switch (item.role) {
          case 'ADMIN' :
            if(newOwner.role === 'SUDO' || newOwner.role === 'ADMIN') result.push(newOwner);
            break
          case 'MANAGER' :
            if(newOwner.role === 'SUDO' || newOwner.role === 'ADMIN') result.push(newOwner);
            break
          case 'CLIENT' :
            if(newOwner.role === 'SUDO' || newOwner.role === 'ADMIN' || newOwner.role === 'MANAGER') result.push(newOwner);
            break
        }
      }
      return result;
    },
    generateSecret() {
      let self = this;
      let json = {};
      axios({
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + "/rest/settings/generate-2fa-secret",
      }).then(function (response) {
        self.item.secret = response.data;
      });
    },
    editItem(item) {
      this.item = this.$global.clone(item);
      this.editDialog = true;
    },
    cancelEditItem() {
      this.item = {};
      this.editDialog = false;
    },
    saveItem() {
      if(this.item.login.length > 0 && this.item.password.length > 0 && this.item.role.length > 0) {
        this.$emit('save-item', this.item, 'users');
        this.cancelEditItem();
      } else {
        this.alert_message = "Заполните обязательные поля.";
        this.alert_header = "Внимание!"
        this.alert_switch = true;
      }
    },

    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
