<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
<template>
  <default-layout-dynamic>
    <loader @close="loader = false" v-model="loader"></loader>

    <v-row :dense="true">
      <v-col cols="12">
        <v-textarea v-model="request" label="Введите текст запроса"></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn color="#0f8107" block @click="search">ОТПРАВИТЬ ЗАПРОС</v-btn>
      </v-col>


      <v-col cols="12">

      <v-table :density="'compact'">
        <tbody>
        <lazy-component wrapper-tag="tr" v-for="i in items" :key="i.id">

          <td style="width: 5%">{{ i.id }}</td>
          <td style="width: 10%">{{ $global.displayDate(i.date, 'DD.MM.YYYY HH:mm') }}</td>
          <td style="width: 15%">{{ i.content }}</td>
          <td style="width: 45%">{{ i.response }}</td>
          <td style="width: 5%">
            <v-icon v-if="i.processed" small color="#0f0">mdi-check</v-icon>
            <v-icon v-else small color="#f00">mdi-close</v-icon>
          </td>
          <td style="width: 10%">{{ $global.displayDate(i.dateProcessed, 'DD.MM.YYYY HH:mm') }}</td>
<!--          <td style="width: 20%">-->
<!--            <v-btn :disabled="!i.processed || i.error.length > 0" size="small" color="#0f0" @click="openDialog(i)">OPEN</v-btn>-->
<!--            <v-btn :disabled="!i.processed || i.error.length > 0" size="small" color="#00f" @click="csv(i.id)">CSV</v-btn>-->
<!--            <v-btn :disabled="!i.processed || i.error.length > 0" size="small" color="#00f" @click="pdf(i.id)">PDF</v-btn>-->
<!--          </td>-->

          <template #placeholder>
            <tr>
              <td colspan="6">Loading...</td>
            </tr>
          </template>
        </lazy-component>

        </tbody>
      </v-table>

      </v-col>
    </v-row>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message"
            :header="alert_header"></ahtung>
    <item-dialog :item="item" :show="showDialog" @close="closeDialog()" :mobile="m"></item-dialog>

  </default-layout-dynamic>
</template>

<script>
import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import router from "@/router/router";
import axios from "axios";
import Ahtung from "@/components/Ahtung.vue";
import ItemDialog from "@/components/ItemDialog.vue";
// import VueDatePicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css'
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Loader,
    ItemDialog,
    Ahtung,
    DefaultLayoutDynamic,
    // VueDatePicker,
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      request: "",
      response: "",
      loader: false,
      parameters: {

      },

      show_filters: true,

      items: [],

      alert_switch: false,
      alert_message: "",
      alert_header: "",
      item: {},
      showDialog: false,

      m: true,
    }
  },
  name: "MainPage",
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
    this.get_items();
  },
  created() {
    if (!this.loggedIn) router.push('/start')
    else {
      console.log("MAIN");
    }
    // this.get_items();
  },
  methods: {
    zeroParameterExist() {
      for (let k in this.parameters) if (this.parameters[k] === 0) return true;
      return false
    },
    openDialog(i) {
      this.item = i;
      this.showDialog = true;
    },
    closeDialog() {
      this.item = {};
      this.showDialog = false;
    },

    search() {
      let self = this;
      self.loader = true;
      let json = {request: this.request}
      axios.post(self.restUrl + "/rest/search", json, {
        headers: self.$global.headers(this.$store.state.auth.user)
      }).then(r => {
        if(r.data.status) self.request = "";
        self.loader = false;
      }).catch(error => {
        self.alert_message = "Ошибка выполнения поискового запроса." + error;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.loader = false;
      });
    },

    get_data() {
      let self = this;
      self.loader = true;
      let json = {}
      axios.post(self.restUrl + "/rest/data", json, {
        headers: self.$global.headers(this.$store.state.auth.user)
      }).then(r => {
        self.busy = r.data.busy;
        self.loader = false;
      }).catch(error => {
        self.alert_message = "Ошибка выполнения поискового запроса." + error;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.loader = false;
      });
    },

    get_items() {
      let self = this;
      self.loader = true;
      let json = {}
      axios.post(self.restUrl + "/rest/items", json, {
        headers: self.$global.headers(this.$store.state.auth.user)
      }).then(r => {
        if(r.data.status) self.items = r.data.items;
        self.loader = false;
      }).catch(error => {
        self.alert_message = "Ошибка выполнения поискового запроса." + error;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.loader = false;
      });
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    '$store.state.messagesUpdater'(val) {
      console.log(val);
      this.get_items();
    },
    xxx: {
      handler() {
        this.search();
      },
      deep: true
    },
    '$vuetify.display.mdAndUp'(val) {
      console.log(!val);
      this.m = !val;
    }
  },
};
</script>
