import {createRouter, createWebHistory} from "vue-router";
import Main from "@/pages/Main";
import P404 from "@/pages/P404";
import P403 from "@/pages/P403";
import Start from "@/pages/Start";
import Settings from "@/pages/Settings.vue";

const routes = [
    { path: '/', component: Main},
    { path: '/start', component: Start},
    { path: '/403', component: P403 },
    { path: '/settings', component: Settings, name: 'settings', beforeEnter: (to, from, next) => { if (!localStorage.getItem('user')) next('/start'); else next(); }},
    { path: '/:pathMatch(.*)*', component: P404 },
]

const router = createRouter( { routes, history: createWebHistory(process.env.BASE_URL) })

// router.beforeEach((to, from, next) => {
//
//     console.log('Before');
//     console.log(to);
//     console.log(from);
//     console.log(next());
//
// });

// router.afterEach((to, from, failure) => {
//     console.log('After');
//     console.log(to);
//     console.log(from);
//     console.log(failure);
//     if (!failure) sendToAnalytics(to.fullPath)
// })

export default router;
