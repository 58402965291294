<template>
  <v-dialog :persistent="true" :model-value="value">
    <v-dialog hide-overlay :persistent="true" :model-value="true" width="300">
        <v-progress-circular
            @click="disableLoader"
            rotate="270"
            size="150"
            :value="percent"
            width="10"
            color="#34e319"
            :indeterminate="!percent || percent === 100"
        ><span :style="'font-size: 27px;'" v-if="percent || percent === 0">{{ percent }}</span>
        </v-progress-circular>
    </v-dialog>
  </v-dialog>
</template>
<script>
export default {
  name: 'LoaderComponent',
  props: [
    'value',
    'percent'
  ],
  methods: {
    disableLoader() {
      console.log("close loader")
        this.$emit('close');
    }
  }
}
</script>
