<template>

  <v-dialog :model-value="value" :max-width="1000" :scrollable="true" persistent>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ header }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :block="true" x-small @click="hide()">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
export default {
  name: 'AhtungComponent',
  props: {
    message: {type: String, required: false, default: ''},
    header: {type: String, required: false, default: ''},
    value: {type: Boolean, required: true, default: false},
  },
  methods: {
    hide() {
      this.$emit('close');
    }
  }
}
</script>
