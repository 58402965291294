import { createStore } from 'vuex'
import { auth } from "./auth.module";
import { websocket } from "./websocket.module";
export default createStore({
  state: {
    sidebar: true,
    messagesUpdater: {},
    shutdownObj: {run: false, min: 0, len: 0},
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    updateMessages(state, val) {
      console.log(val);
      state.messagesUpdater = val;
    },
  },
  actions: {
    async handleUpdateResearches({commit}, val) {
      console.log(val);
      commit('updateResearches', val);
    },
    // updateOnBack(context, id) {
    //   if (context.state.websocket.stompClient && context.state.websocket.stompClient.connected) {
    //     context.state.websocket.stompClient.publish({
    //       destination: '/back',
    //       body: JSON.stringify({"id": id, "userId": context.state["auth/user"].id}),
    //     });
    //   }
    // },
    async handleToggleSideBar({commit}) {
      commit('toggleSidebar');
    },
  },
  modules: {
    auth,
    websocket
  }
})
