<style>
.bg {
  background-image: url('http://localhost:3005/images/zebra.jpg');
}
</style>
<template>
  <empty-layout-dynamic>

    <v-toolbar>

      <v-spacer></v-spacer>

      <v-btn icon :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="toggleTheme()"><v-icon>mdi-theme-light-dark</v-icon></v-btn>

      <login-button></login-button>

    </v-toolbar>

  </empty-layout-dynamic>

</template>

<script>
import LoginButton from "../components/LoginButton.vue";
import {useTheme} from "vuetify";
import EmptyLayoutDynamic from "@/components/layouts/EmptyLayoutDynamic";
export default {
  name: "StartPage",
  setup () {
    const theme = useTheme();
    return { theme, toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'};
  },
  components: {
    EmptyLayoutDynamic,
    LoginButton
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      urlFront: process.env.VUE_APP_FRONTEND_BASE_URL,

    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
  },
  methods: {

  },
};
</script>
